import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from "./components/Header";
import {connect} from 'react-redux';
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from '@material-ui/core/Menu';



const Navbar = ({currentUser}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const{ setUser,user }=props;
  return (
      <>
      <div className="container-fluid nav_bg">
      <div className="row">
        <div className="col-12 mx-auto">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <NavLink className="navbar-brand" to="/">
                Vasant Labels
              </NavLink>
<div>
              <IconButton edge="start" aria-label="menu" onClick={handleClick}>
      <MenuIcon />
   
    </IconButton>
    <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
    
      >
      <li className="nav-item">
        <NavLink  className="nav-link" to="/service">Services</NavLink>
        <NavLink activeClassName="menu_active" className="nav-link" to="/about">About</NavLink>
        <NavLink activeClassName="menu_active" className="nav-link" to="/contact">Contact</NavLink>
        </li>
      </Menu>
              
    </div>    
            </div>
          </nav>
        </div>
      </div>
    </div>
    </> 
  );
}

const mapSateToProps = state =>({
  currentUser : state.user.currentUser
});

export default connect(mapSateToProps)(Navbar);
