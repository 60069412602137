import React from 'react';
import { Switch, Route, Redirect,withRouter } from "react-router-dom";
import {setCurrentUser} from '../../redux/user/user.actions';
import {connect} from 'react-redux';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {ListItems} from './ListItems';
import ProductsInfo from '../ProductsInfo/ProductsInfo';
import DashboardHome from './DashBoard/DashboardHome';
import {selectCurrentUser} from '../../redux/user/user.selectors';
import {createStructuredSelector} from 'reselect';
import vasantLabelsLogo from "../../images/vasantLabelsLogo.png";
import OrderPage from './OrderPage';
import CustomersManage from './CustomersManage';
import InvoiceDownload from './InvoiceDownload';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://www.vasantslabels.com/">
//         vasantslabels
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  userTitle: {
    position: 'absolute',
    right: '10px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  img: {
    width: "35px",
    top: "22px",
  },
}));

const Dashboard =({history,setCurrentUser,currentUser,shoppingData})=> {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const {
    mainListItems,
    secondaryListItems
  } = ListItems(history,setCurrentUser);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <img src={vasantLabelsLogo} className={classes.img}/>
            Vasants Labels Dashboard
          </Typography>
          {
          (currentUser!==null) ?
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.userTitle}>
          Hello,{currentUser.username}
          </Typography> : null
          }
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      <Switch>
        <Route exact path="/adDashboard"  render={() => <Redirect to="/adDashboard/dashboard" />} />
        <Route exact path="/"  render={() => <Redirect to="/adDashboard/dashboard" />} />
        <Route eaxct path="/adDashboard/dashboard" component={DashboardHome} />
        <Route exact path="/adDashboard/upload" component={ProductsInfo} />
        <Route exact path="/adDashboard/orders" component={OrderPage} />
        <Route exact path="/adDashboard/customers" component={CustomersManage} />
        <Route exact path="/adDashboard/invoicedownload" component={InvoiceDownload} />
        {/* <Redirect to="/"/> */}
      </Switch>
    </div>
  );
}

const mapSateToProps = createStructuredSelector ({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch =>({
  setCurrentUser: user =>dispatch(setCurrentUser(user))
});

export default withRouter(connect(
  mapSateToProps,mapDispatchToProps
      )(Dashboard));