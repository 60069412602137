import React,{useState } from "react";
import { Grid,TextField,makeStyles,InputLabel,CssBaseline} from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm,Form } from '../../components/form-input/useForm';
import * as employeeService from "../../services/employeeService";
import { withRouter } from 'react-router-dom';
import Notification from '../../components/Notification';

const initialFValues = {
  ac_type:'',
  ac_code:'',
  ac_name:'',
  ac_addr1:'',
  ac_addr2:'',
  ac_addr3:'',
  ac_addr4:'',
  country:'',
  area:'',
  tel_no:'',
  mobile_no:'',
  fax_no:'',
  email:'',
  vat_no:'',
  prop_name:'',
  purc_mangr:'',
  accountant:'',
  acnt_email:'',
  ms_oms:'',
  form_no:'',
  credit_day:'',
  discount:'',
  grade:'',
  fact_name:'',
  fperson:'',
  faddr1:'',
  faddr2:'',
  faddr3:'',
  faddr4:'',
  ftel_no:'',
  pick:'',
  courier_yn:'',
  remark1:'',
  coref_yn:'',
  eoreg_yn:'',
  emispo_yn:'',
  etreg_yn:'',
  grp_code:'',
  enq_bill:'',
  pord_yn:'',
  a_i:'',
  ex_copy:'',
  currency:'',
  bvuser_cd:'',
  bvuser_nm:'',
  bchk_pwd:'',
  frgt_zone:'',
  coac_yn:'',
  parent_cd:'',
  bill_type:'',
  brnd_vendr:'',
  aqty_yn:'',
  tran_lval:'',
  courier:'',
  ac_no:'',
  bill_code:'',
  email1:'',
  email2:'',
  gst_no:'',
  city:'',
  state:'',
  zip:'',
  bill_to:'',
  bsubmit:'',
  customer:'',
  elite:'',
  kms:'',
  sstype:'',
  tmode:'',
  tgst_no:'',
  trans_id:'',
  state_nm:'',
  city_nm:'',
  country_nm:'',
  area_nm:'',
  curr_nm:'',
  bill_tonm:'',
  stype_nm:'',
  tmode_nm:'',
}

const initialUserDetails = {
    person:'',
    user_cd:'',
    user_pwd:'',
    tel_noa:'',
    emaila:'',
}


const initialcourierdetails={
    courierTobeArranged: '',
}


const useStyles = makeStyles(theme => ({
    form:{
    paddingBottom: theme.spacing(3),
    },
    userdetails:{
        paddingBottom: theme.spacing(3),
    },
    list:{
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft:theme.spacing(1),
        paddingRight:theme.spacing(1),
    },
    listTagline:{
        
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft:theme.spacing(1),
        paddingRight:theme.spacing(1),
        color: "#3498db",
        fontSize: 20
    },
    addresslist:{
        textAlign:"center"
    },
    tagline:{
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
        textAlign:"center",
        color: "#3498db",
        fontSize: 30
        
    },
    tagline2:{
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        // textAlign:"center",
        color: "red",
    },
    courierTagline:{
        paddingTop: theme.spacing(1),
        paddingLeft:theme.spacing(1),
        color: "#3498db",
        fontSize: 15
    }
}))

// const BrandBuyerForm = ({setUser,randomNumber,setRandomNumber,history,Details,setDetails,setUsersist,userslist,setCountry,setState,country,state}) => {
    const BrandBuyerForm = ({setUser,setSpinnerActive,randomNumber,setRandomNumber,history,Details,setDetails,setUsersist,userslist}) => {

    // const [userError,setUserError]= useState();
    // const [regionData,setRegionData]= useState({
    //     country: [],
    //     state: [],
    //     city: [],
    // });
    // const [country,setCountry]= useState([]);
    // const [state,setState]= useState([]);
    // const [city,setCity]= useState([]);

    const [usersCount,setCount]= useState(1);
    const [Userslist,setUserslist] = useState({
        userslist: [],
    });
    const [Addresslist,setAddresslist] = useState({
        addresslist: [],
    })
    const [notify, setNotify] = useState({ isOpen:false,message: '',type: ''})
    const classes = useStyles();

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('grp_code' in fieldValues)
            temp.grp_code = fieldValues.grp_code.length !== 0 ? "" : "This field is required."
        if ('ac_name' in fieldValues)
            temp.ac_name = (fieldValues.ac_name) ? "" : "This field is required."
        if ('ac_addr1' in fieldValues)
            temp.ac_addr1 = (fieldValues.ac_addr1) ? "" : "This field is required."
        if ('gst_no' in fieldValues)
            temp.gst_no = (fieldValues.gst_no.length===0) || (fieldValues.gst_no.length===15) ? "" : "This field is required."
        if ('vat_no' in fieldValues)
            temp.vat_no = (fieldValues.vat_no.length===0)||(fieldValues.vat_no.length<=30) ? "" : "This field is required."
        if ('acnt_email' in fieldValues)
            temp.acnt_email = (/$^|.+@.+..+/).test(fieldValues.acnt_email) ? "" : "Email Is Not Valid."
        if ('country' in fieldValues)
            temp.country = fieldValues.country ? "" : "This field is required."
        if ('state' in fieldValues)
            temp.state =  (fieldValues.country === "I001")? (fieldValues.state ? "" : "This feild is required") : ""  
        if ('zip' in fieldValues)
            temp.zip = fieldValues.zip ? "" : "This field is required."
        if ('tel_no' in fieldValues)
            temp.tel_no = !isNaN(fieldValues.tel_no) ? "" : "Telephone Number Is Not valid."
        if ('prop_name' in fieldValues)
            temp.prop_name = (fieldValues.prop_name.length != 0) || (fieldValues.prop_name.length <=30) ? "" : "This field is required."
        if ('mobile_no' in fieldValues)
            temp.mobile_no = (!isNaN(fieldValues.mobile_no) && fieldValues.mobile_no.length===0) || (fieldValues.mobile_no.length <= 30) || (fieldValues.mobile_no.length===0)  ? "" : "Mobile Number Is Not valid."
        if ('email' in fieldValues)
            temp.email = ((/$^|.+@.+..+/).test(fieldValues.email) && (fieldValues.email.length <=50)) || (fieldValues.email.length ===0) ? "" : "Email Is Not Valid."  
        if ('purc_mangr' in fieldValues)
            temp.purc_mangr = (fieldValues.purc_mangr.length === 0) || fieldValues.purc_mangr.length <=30 ? "" : "This field is required."
        if ('accountant' in fieldValues)
            temp.accountant = (fieldValues.accountant.length === 0) || fieldValues.accountant.length <=30 ? "" : "This field is required."
        if ('acnt_email' in fieldValues)
            temp.acnt_email = ((/$^|.+@.+..+/).test(fieldValues.acnt_email) && fieldValues.acnt_email.length <=30) || (fieldValues.acnt_email.length ===0) ? "" : "Email Is Not Valid."
        if ('email1' in fieldValues)
            temp.email1 = ((/$^|.+@.+..+/).test(fieldValues.email1) && fieldValues.email1.length <=50) || (fieldValues.email1.length ===0) ? "" :  "Email Is Not Valid."
        if ('email2' in fieldValues)
            temp.email2 = ((/$^|.+@.+..+/).test(fieldValues.email2)  && (fieldValues.email2.length <=50)) || fieldValues.email2.length ===0 ? "" :  "Email Is Not Valid."
        if ('courier_yn' in fieldValues)
            temp.courier_yn = fieldValues.courier_yn? "" : "This field is required."
        if ('ac_no' in fieldValues)
            temp.ac_no = (fieldValues.courier_yn != 'N') ? "" : ((fieldValues.ac_no) ? "" : "This field is required.")
        if ('bill_code' in fieldValues)
            temp.bill_code = (fieldValues.courier_yn != 'N')?  "" : ((fieldValues.bill_code) ? "" : "This field is required.")  
               
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const validateUserDetails = (fieldValues = data) => {
        let temp = { ...userError }
        
        if ('emaila' in fieldValues)
            temp.emaila = (/$^|.+@.+..+/).test(fieldValues.emaila) && (fieldValues.emaila.length <=50) ? "" : "Email Is Not Valid."
        if ('person' in fieldValues)
            temp.person = fieldValues.person.length<=30 ? "" : "This feild will not allow more than 30 characters."
        if ('user_pwd' in fieldValues)
            temp.user_pwd = fieldValues.user_pwd.length<=30  ? "" : "This feild will not allow more than 30 characters."
        if ('user_cd' in fieldValues)
            temp.user_cd = fieldValues.user_cd.length<=30  ? "" : "This feild will not allow more than 30 characters."
        if ('tel_noa' in fieldValues)
            temp.tel_noa = (!isNaN(fieldValues.tel_noa) && fieldValues.tel_noa.length === 10) ? "" : "Mobile Number Is Not valid."
        
        setUserError({
            ...temp
        })

        if (fieldValues === data)
            return Object.values(temp).every(x => x === "")
    }
    const validatecourierTobeArranged = (fieldValues = courierTobeArranged) => {
        let temp = { ...courierError }

        if ('courierTobeArranged' in fieldValues)
            temp.courierTobeArranged = fieldValues.courierTobeArranged.length !== 0 ? "" : "This field is required."
        if ('ac_no' in fieldValues)
            temp.ac_no = (courierTobeArranged.courierTobeArranged  === 2)? ((fieldValues.ac_no.length===4) ? "" : "This field is required.") : ""
        if ('bill_code' in fieldValues)
            temp.bill_code = (courierTobeArranged.courierTobeArranged  === 2)? (fieldValues.bill_code.length <=30 ? "" : "This field is required.") : ""
        setcourierError({
            ...temp
        })
        if (fieldValues === courierTobeArranged)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        data,
        courierTobeArranged,
        country,
        state,
        city,
        brands,
        setValues,
        setUserData,
        
        errors,
        userError,
        courierError,
        setErrors,
        setUserError,
        setcourierError,
        handleInputChange,
        handleInputChangeforBrand,
        handleInputChangeforAccountName,
        handleInputChangefroAddress,
        handleInputChangeforCountry,
        handleInputChangeforState,
        handleInputChangeforCity,
        handleInputChangeforTel_no,
        InputEvent,
        handleInputChangeforCourier_yn,
        handleInputChangeforBill_code,
        resetForm,
        resetUserData,
        resetCourierTobeArranged,
    } = useForm(initialFValues,initialUserDetails,initialcourierdetails, true, validate,validateUserDetails,validatecourierTobeArranged,null);
    
    const handleSubmit = async(e) => {
        e.preventDefault() 
        if (validate()){       
            if (usersCount!==1){
                resetForm();
                resetCourierTobeArranged();
                resetUserData();
                setUser(true);
                setSpinnerActive(true);
                // const token = localStorage.getItem('token');
                // const userName= localStorage.getItem('tempUsername');
                localStorage.clear();
                // localStorage.setItem("username",userName);
                // localStorage.setItem("token",token);
                setNotify({
                    isOpen: true,
                    message: 'Record under verification, If admin allows you can start shopping 👍',
                    type: 'success'
                })
                history.push({
                    pathname: '/'
                });
            }
        }
    }

    const handleSubmitAndRequestAddDeliveyAddress = async(e) => {
        e.preventDefault();
            if (validate()){
                if(validateUserDetails()){
                    await employeeService.insertVendersFactoryUsercase1(values,data,usersCount,setCount,randomNumber,Userslist,setUserslist,history);
                    resetUserData(); 
                }
                if (usersCount > 1){
                   
                    let AddressValues={
                        ...values,
                        tagline:Details,                      
                    }
                    setDetails(`Delivery Details ${Addresslist.addresslist.length + 1}`);
                    setUserslist({
                        userslist:[]})
                    setAddresslist({
                        addresslist:[
                            ...Addresslist.addresslist,
                            AddressValues]})
                    setCount(1);
                    setRandomNumber((Math.floor((Math.random() * 10000)+(Math.random() * 10000)+(Math.random() * 100)) + 1));
                    //*// Reset form As per Client Requirment
                    setValues({
                        ac_type:values.ac_type,
                        ac_code:'',
                        ac_name: values.ac_name,
                        ac_addr1:values.ac_addr1,
                        ac_addr2: values.ac_addr2,
                        ac_addr3:values.ac_addr3,
                        ac_addr4:values.ac_addr4,
                        country:values.country,
                        area:values.area,
                        tel_no:'',
                        mobile_no:'',
                        fax_no:'',
                        email:'',
                        vat_no:'',
                        prop_name:'',
                        purc_mangr:'',
                        accountant:'',
                        acnt_email:'',
                        ms_oms:'',
                        form_no:values.form_no,
                        credit_day:values.credit_day,
                        discount:values.discount,
                        grade:values.grade,
                        fact_name:'',
                        fperson:'',
                        faddr1:'',
                        faddr2:'',
                        faddr3:'',
                        faddr4:'',
                        ftel_no:'',
                        pick:'',
                        courier_yn:values.courier_yn,
                        remark1:'',
                        coref_yn:'',
                        eoreg_yn:'',
                        emispo_yn:'',
                        etreg_yn:'',
                        grp_code:values.grp_code,
                        enq_bill:values.enq_bill,
                        pord_yn:values.pord_yn,
                        a_i:values.a_i,
                        ex_copy:values.ex_copy,
                        currency:values.currency,
                        bvuser_cd:values.bvuser_cd,
                        bvuser_nm:values.bvuser_nm,
                        bchk_pwd:values.bchk_pwd,
                        frgt_zone:'',
                        coac_yn:values.coac_yn,
                        parent_cd:values.parent_cd,
                        bill_type:values.bill_type,
                        brnd_vendr:'',
                        aqty_yn:values.aqty_yn,
                        tran_lval:values.tran_lval,
                        courier:'',
                        ac_no:values.ac_no,
                        bill_code:values.bill_code,
                        email1:'',
                        email2:'',
                        gst_no:'',
                        city: values.city,
                        state: values.state,
                        zip: values.zip,
                        bill_to:'',
                        bsubmit:values.bsubmit,
                        customer:'',
                        elite:values.elite,
                        kms:'',
                        sstype:'',
                        tmode:'',
                        tgst_no:'',
                        trans_id:'',
                        state_nm:values.state_nm,
                        city_nm:values.city_nm,
                        country_nm:values.country_nm,
                        area_nm:values.area_nm,
                        curr_nm:values.curr_nm,
                        bill_tonm:'',
                        stype_nm:'',
                        tmode_nm:'',
                        user_cd:'',
                    });
                    setErrors({});
                    //*// resetForm();
                    // resetCourierTobeArranged();
                }
            }
    }
    const handleSubmitUser = async(e) =>{
        e.preventDefault();
        if(validate()){
        if(validateUserDetails()){
        // console.log(randomNumber);
        await employeeService.insertVendersFactoryUsercase1(values,data,usersCount,setCount,randomNumber,Userslist,setUserslist,setUserData,resetUserData,history);
        // console.log(preview.userslist);
        // await employeeService.insertVendersFactoryUsercase2(data,randomNumber,usersCount,setCount);
        // console.log(data);
        }
        }
    }
   const defaultvalue="";
    return (
        <>
        <Form >
            <Grid container className={classes.form}>
                <Grid item xs={12} sm={12}>
                <InputLabel className={classes.tagline}>
                        {Details}
                </InputLabel>
                </Grid>
                <Grid item xs={6} sm={3} >
                {
                    (Addresslist.addresslist.length===0) ?
                    <>
                    <Controls.Select4
                        name="grp_code"
                        label="Select Brand  *"
                        value={values.grp_code}
                        onChange={handleInputChangeforBrand}
                        options={brands}
                        error={errors.grp_code}
                    />
                    </>
                    :
                    <>
                    <TextField
                 disabled
                 id="standard-disabled"
                 label="Select Brand  *"
                value={values.grp_code}
                 />
                 </>    
                }
                <TextField
                 disabled
                 id="standard-disabled"
                 label="Account Code"
                 value={defaultvalue} 
                 />
                 <Controls.Input
                        name="ac_name"
                        label="Account Name *"
                        value={values.ac_name}
                        onChange={handleInputChangeforAccountName}
                        error={errors.ac_name}
                        
                />
                {/* <Controls.Input
                        name="accountCode"
                        label="Account Code *"
                        value={values.accountCode}
                        onChange={handleInputChange}
                        // error={errors.accountCode}
                /> */}
                </Grid>
                <Grid item xs={12} sm={12} >
                <Controls.Input
                        name="ac_addr1"
                        label="AddressLine 1 *"
                        value={values.ac_addr1}
                        onChange={handleInputChangefroAddress}
                        error={errors.ac_addr1}
                    />
                <Controls.Input
                        name="ac_addr2"
                        label="AddressLine 2"
                        value={values.ac_addr2}
                        onChange={handleInputChangefroAddress}
                    />
                <Controls.Input
                        name="ac_addr3"
                        label="AddressLine 3"
                        value={values.ac_addr3}
                        onChange={handleInputChangefroAddress}
                    />
                    <Controls.Input
                        name="ac_addr4"
                        label="AddressLine 4"
                        value={values.ac_addr4}
                        onChange={handleInputChangefroAddress}
                    />
                    
                </Grid>
                <Grid item xs={12} sm={7}>
                {/* <Controls.Input
                        name="country"
                        label="Country *"
                        value={values.country}
                        onChange={handleInputChange}
                        error={errors.country}
                        
                    /> */}
                     <Controls.Select2
                         name="country"
                         label="Country *"
                         value={values.country}
                         onChange={handleInputChangeforCountry}
                        error={errors.country}
                        options={country}
                    />
                    {
                    values.country=== "I001" ? (
                    <Controls.Select2
                        name="state"
                        label="State *"
                        value={values.state}
                        onChange={handleInputChangeforState}
                        options={state}
                        error={errors.state}
                    />
                    ):""
                    }
                   
                    {
                        (values.state !== "")? 
                        (
                        <Controls.Select2
                        name="city"
                        label="City"
                        value={values.city}
                        onChange={handleInputChangeforCity}
                        options={city}
                        />
                        ) : ""
                    }

                   
                    <Controls.Input
                        name="zip"
                        label="Zip Code *"
                        value={values.zip}
                        onChange={handleInputChange}
                        error={errors.zip}
                        
                    />
                    
                    {
                    values.country=="I001" && values.country!="" ? (
                        <Controls.Input
                        name="gst_no"
                        label="GST Details *"
                        value={values.gst_no}
                        onChange={handleInputChange}
                        error={errors.gst_no}
                        />
                    )
                    :""
                    }
                    {       
                    values.country!=="I001" && values.country!==""  ? (
                        <Controls.Input
                        name="vat_no"
                        label="VAT Details *"
                        value={values.vat_no}
                        onChange={handleInputChange}
                        error={errors.vat_no}
                        />    
                    ):""
                    }
    
                    <Controls.Input
                        name="tel_no"
                        label="Company Telephone Number"
                        value={values.tel_no}
                        onChange={handleInputChangeforTel_no}
                        error={errors.tel_no}
                    />
                    
                    {
                    Details=="Billing Details"?
                    <>
                    <Controls.Input
                    name="prop_name"
                    label="Name of Company owner *"
                    value={values.prop_name}
                    onChange={handleInputChange}
                    error={errors.prop_name}
                    /> 
                    <Controls.Input
                        name="mobile_no"
                        label="Owner Mobile Number"
                        value={values.mobile_no}
                        onChange={handleInputChange}
                        error={errors.mobile_no}
                    />
                    <Controls.Input
                        name="email"
                        label="Owner Email Address"
                        value={values.email}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                    </>
                    :
                    <>
                    <Controls.Input
                    name="prop_name"
                    label="Name of Factory Incharge *"
                    value={values.prop_name}
                    onChange={handleInputChange}
                    error={errors.prop_name}
                    />
                    <Controls.Input
                        name="mobile_no"
                        label=" Factory Incharge Mobile Number"
                        value={values.mobile_no}
                        onChange={handleInputChange}
                        error={errors.mobile_no}
                    />
                    <Controls.Input
                        name="email"
                        label=" Factory Incharge Email Address"
                        value={values.email}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                    </>
                    }
                   
                    
                    <Controls.Input
                        name="purc_mangr"
                        label="Purchase Manager Name"
                        value={values.purc_mangr}
                        onChange={handleInputChange}
                        error={errors.purc_mangr}
                    />
                    <Controls.Input
                        name="accountant"
                        label="Accountants Name"
                        value={values.accountant}
                        onChange={handleInputChange}
                        error={errors.accountant}
                    />
                    <Controls.Input
                        name="acnt_email"
                        label="Chief Accounts Email Address *"
                        value={values.acnt_email}
                        onChange={handleInputChange}
                        error={errors.acnt_email}
                    />
                    <Controls.Input
                        name="email1"
                        label="Accounts Email Address"
                        value={values.email1}
                        onChange={handleInputChange}
                        error={errors.email1}
                    />
                    <Controls.Input
                        name="email2"
                        label="Accounts Email Address 2"
                        value={values.email2}
                        onChange={handleInputChange}
                    />
                   
                    {
                    (Addresslist.addresslist.length===0) ?
                    <>
                    <Controls.Select
                        name="courier_yn"
                        label="Courier to be Arranged by Vasants Labels ?  *"
                        value={values.courier_yn}
                        onChange={handleInputChangeforCourier_yn}
                        options={employeeService.getCourierToBeArranged()}
                        error={errors.courier_yn}
                    />

                    <Grid item xs={12} sm={12} >
                    {
                    (values.courier_yn == 'N')  ?
                    <>
                    <Grid item xs={6} sm={6} >
                    <Controls.Select
                        name="ac_no"
                        label="Courier Name ?  *"
                        value={values.ac_no}
                        onChange={handleInputChange}
                        options={employeeService.getCouriers()}
                        error={errors.ac_no}
                    />
                    </Grid>
                    {
                        (values.ac_no !=='')?
                        <>
                        <Grid item xs={12} sm={12} >
                        <InputLabel className={classes.courierTagline}>Your Import Account Number With {values.ac_no} Courier</InputLabel>
                        </Grid>
                        <Grid item xs={6} sm={4} >
                        <Controls.Input
                        name="bill_code"
                        value={values.bill_code}
                        onChange={handleInputChangeforBill_code}
                        error={errors.bill_code}    
                        />
                        </Grid>
                        </>: null
                    }
                    
                    </>: null
                    }
                    </Grid> 

                    </>:null
                    }
                    

                    </Grid>
            </Grid>
            </Form>
            <Form>
            <Grid container className={classes.userdetails}>
                <Grid item xs={12}>
                    <InputLabel className={classes.tagline2}>
                    pls fill in these columns only if you would like to give access to your staff who can edit/enter/view orders on your behalf
                    </InputLabel>
                </Grid>
                <Grid item xs={5} sm={2}>
                    <Controls.Input
                        name="person"
                        label="Name *"
                        value={data.person}
                        onChange={InputEvent}
                        error={userError.person}
                    />
                </Grid>
                <Grid item xs={5} sm={2}>
                    <Controls.Input
                        name="user_cd"
                        label="Login Id *"
                        value={data.user_cd}
                        onChange={InputEvent}
                        error={userError.user_cd}
                    />
                </Grid>
                <Grid item xs={5} sm={2}>
                    <Controls.Input
                        name="user_pwd"
                        label="Password *"
                        value={data.user_pwd}
                        onChange={InputEvent}
                        error={userError.user_pwd}
                    />
                </Grid>
                <Grid item xs={5} sm={3}>
                    <Controls.Input
                    name="emaila"
                    label="Email *"
                    value={data.emaila}
                    onChange={InputEvent}
                    error={userError.emaila}
                    />
                </Grid>
                <Grid item xs={5} sm={2}>
                    <Controls.Input
                    name="tel_noa"
                    label="Mobile No *"
                    value={data.tel_noa}
                    onChange={InputEvent}
                    error={userError.tel_noa}
                    />
                </Grid>
                <Grid item xs={2} sm={1}>
                    <Controls.Button
                    // type="submit"
                    text="Add"
                    color="default" 
                    onClick={handleSubmitUser}
                    />
                </Grid>
            </Grid>
            </Form>
            {
                (Userslist.userslist.length > 0)?
                <>
                <Grid container className={classes.form}>
                <Grid item xs={6} sm={12}>
                    <CssBaseline>
                    <table>
                    <tbody>
                    {Userslist.userslist.map((val, ind) => (
                        <tr key={ind}>
                        <td><InputLabel className={classes.list}>{val.person}</InputLabel></td>
                        <td><InputLabel className={classes.list}>{val.user_pwd}</InputLabel></td>
                        <td><InputLabel className={classes.list}>{val.emaila}</InputLabel></td>
                        <td><InputLabel className={classes.list}>{val.tel_noa}</InputLabel></td>
                        </tr>
                    ))
                    }
                    </tbody>
                    </table>
                    </CssBaseline>

                </Grid>
                </Grid>
                </> : ""

            }
        
            <Grid container className={classes.form}>
                <Grid item xs={6} sm={6}>
                    <div>
                        <Controls.Button
                            // type="submit"
                            text="Submit"
                            onClick={handleSubmit} 
                        />
                        <Controls.Button
                            text="Reset"
                            color="default"
                            onClick={resetForm} 
                        />
                    </div>
                </Grid>
                <Grid item xs={6} sm={6}>

                    <div>
                        <Controls.Button
                            color="default"
                            text="Add Delivery Address"
                            onClick={handleSubmitAndRequestAddDeliveyAddress}
                        />
                    </div>
                </Grid>
                </Grid>
                {
                    (Addresslist.addresslist.length > 0) ?
                    <>
                    <Grid container>
                    {Addresslist.addresslist.map((val, ind) => (
                        <Grid item xs={3} sm={6} key={ind} >
                        <CssBaseline>
                        
                        {(val.tagline!="") ? (<InputLabel className={classes.listTagline} >{val.tagline}</InputLabel>) : ""}
                        {(val.ac_name!="") ? (<InputLabel className={classes.list} >{val.ac_name}</InputLabel>) : ""}
                        {(val.ac_addr1!="") ? (<InputLabel className={classes.list} >{val.ac_addr1}</InputLabel>) : ""}
                        {(val.ac_addr2!="") ? (<InputLabel className={classes.list} >{val.ac_addr2}</InputLabel>) : ""}
                        {(val.ac_addr3!="") ? (<InputLabel className={classes.list} >{val.ac_addr3}</InputLabel>) : ""}
                        {(val.ac_addr4!="") ? (<InputLabel className={classes.list} >{val.ac_addr4}</InputLabel>) : ""}
                        {(val.country!="") ? (<InputLabel className={classes.list} >{val.country}</InputLabel>) : ""}
                        {(val.state!="") ? (<InputLabel className={classes.list} >{val.state}</InputLabel>) : ""}
                        {(val.zip!="") ? (<InputLabel className={classes.list} >{val.zip}</InputLabel>) : ""}
                        </CssBaseline>
                        </Grid> 
                    ))}
                    </Grid>
                    </>
                    : "" 
                    
                }
            
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
}

export default withRouter(BrandBuyerForm);
