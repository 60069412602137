import { createSelector } from 'reselect';

const selectCart = state => state.cart;

export const selectCartItems = createSelector(
  [selectCart],
  cart => cart.cartItems
);

export const selectCorefNo = createSelector(
  [selectCart],
  cart => cart.corefno
);

export const selectCartHidden = createSelector(
  [selectCart],
  cart => cart.hidden
);
export const selectUpdateRateIntoInflatedRate = createSelector(
  [selectCart],
  cart => cart.updateRateIntoInflatedRate
);

// export const selectHeaderHidden = createSelector(
//   [selectCart],
//   cart => cart.headerHidden
// );

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + cartItem.quantity,
      0
    )
);

export const selectCartTotal = createSelector(
  [selectCartItems],
  cartItems =>
    // cartItems.reduce(
    //   (accumalatedQuantity, cartItem) =>
    //     accumalatedQuantity + ((cartItem.quantity) * cartItem.price),
    //   0
    // )
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + ((cartItem.quantity) * (cartItem.rate/100)),
      0
    )
    // cartItems.reduce(
    //   (accumalatedQuantity, cartItem) =>
    //     accumalatedQuantity + (cartItem.quantity/cartItem.linebrk) * getPrice(cartItem.rate/100) ,
    //   0
    // )
);

// const getPrice=(price)=>{
//     if(price % 1 !=0){
//       const result=parseInt(price) + 1;
//       return result;
//     }else{
//       return price;
//     }
// }

export const selectCartOrGSTOrShippingTotal = createSelector(
  [selectCartItems],
  cartItems =>
    // cartItems.reduce(
    //   (accumalatedQuantity, cartItem) =>
    //     accumalatedQuantity + cartItem.quantity * cartItem.price,
    //   0
    // )
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
      (((parseFloat(accumalatedQuantity)/parseFloat(cartItem.linebrk)) + (cartItem.quantity/parseFloat(cartItem.linebrk))) * parseFloat(cartItem.rate)) + ((((parseFloat(accumalatedQuantity)/parseFloat(cartItem.linebrk)) + (cartItem.quantity/parseFloat(cartItem.linebrk))) * parseFloat(cartItem.rate)) * 0.15) + 250 ,
      0
      // selectCartTotal + (selectCartTotal * 1.5) + 250 ,
      // 0
    )
);