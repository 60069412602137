import React, { useState, useEffect } from 'react'
import { Grid,makeStyles,InputLabel, Switch } from '@material-ui/core';
import Controls from "../controls/Controls";
import { Form } from '../useForm';
import axios from 'axios';
import Spinner from '../spinner/spinner.component';



const initialFValues = {
    size:"",
    inflatedRate:"",
    sequence:""
}

const useStyles = makeStyles(theme => ({
    submitbutton1: {
        paddingBottom: theme.spacing(2),
    },
    submitbutton2: {
        paddingBottom: theme.spacing(1),
    },
    note: {
        paddingTop :theme.spacing(2),
        fontSize: 13,
        color: "#ff1744",
    },
    
}))

export default function ProductPopup(props) {
    const classes = useStyles();
    const { addOrEdit,insertInflatedRate,recordForEdit, handleSequenceChange, handleUpdateMoreInfo} = props;
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const [spinnerActive, setSpinnerActive] = useState(false)
    useEffect(() => {
        if (recordForEdit!= null){
            if (recordForEdit.sizes){
                if(recordForEdit.inflatedRate){
                    setValues({
                        ...values,
                        inflatedRate:recordForEdit.inflatedRate,
                        sequence:recordForEdit.sequence,
                        size:recordForEdit.sizes.join().replaceAll(",","|")
                    })
                }else{
                    setValues({
                        ...values,
                        size:recordForEdit.sizes.join().replaceAll(",","|")
                    })
                } 
            }else{
                if(recordForEdit.inflatedRate || recordForEdit.sequence){
                    setValues({
                        ...values,
                        inflatedRate:recordForEdit.inflatedRate,
                        sequence:recordForEdit.sequence
                    })
                }
            }
        }
    }, [])
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('size' in fieldValues)
            temp.size = fieldValues.size ? "" : "This field is required."
        if ('inflatedRate' in fieldValues)
            temp.inflatedRate =!isNaN(fieldValues.inflatedRate) ? "" : "Rate is not valid."
        // if ('sequence' in fieldValues)
        //     temp.sequence =!isNaN(fieldValues.sequence) ? "" : "sequence is not valid."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    
    const handleSubmit = e => {
        e.preventDefault();
        if(values.size){
            addOrEdit(values.size, resetForm);
        }
    }
    const handleSubmitInflatedRate = e =>{
        e.preventDefault();
        if(!isNaN(values.inflatedRate)){
            insertInflatedRate(values.inflatedRate,resetForm);
        }
    }
    const handleInputChange = e => {
        const { name, value } = e.target;
            validate({ [name]: value })
            setValues({
                ...values,
                [name]: value
            })
    }
    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})  
    }
    const handleSubmitSequence = e => {
        e.preventDefault();
        if (!isNaN(values.sequence)) {
            handleSequenceChange(values.sequence, resetForm);
        }
    }

    const handleUploadProductPdf = async (event) => {
        let { files } = event.target;

        let formData = new FormData();
        formData.append('file', files[0]);
        const config = {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data"
            }
        }
        setSpinnerActive(true);
        await axios.post("productsController/uploadProductDetailsPdf", formData, config).then(
            response => {
                setSpinnerActive(false);
                if (response.data !== undefined | null) {
                    alert(`${response.data} for ${recordForEdit.docno} !!!`);
                    handleUpdateMoreInfo(recordForEdit, true);
                }
            }
        )
            .catch(error => {
                setSpinnerActive(false);
                alert("Server Error ! 👎");
            });
    }

    const handleSwitchProductMoreInfo = async (event) => {
        
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const dataObj = {
            "docno": recordForEdit?.docno,
            "moreinfo" : event?.target?.checked
        }
        await axios.post("productsController/updateProductMoreInfoFlag",dataObj,config).then(
         response => {
                if(response.data !== undefined|null ) {
                  alert(`update product more_information flag for ${recordForEdit.docno} !!!`);
                  handleUpdateMoreInfo(recordForEdit, dataObj.moreinfo);
                }
            }
        )
        .catch(error => {
         alert("Server Error ! 👎");
        });
    }
    
    return (
        <Form >
            {
                spinnerActive && <Spinner />
            }
            <InputLabel className={classes.note} >pls note down you need to add size one by one and seperated by | and then submit</InputLabel>
            <Grid container>
                {
                    recordForEdit!==null ?
                    <>
                    {
                        recordForEdit.ordertype.toLowerCase()==="with additional sheet" ?
                        <>
                        <Grid item xs={6}>
                        <Controls.Input
                            name="size"
                            label="Size"
                            value={values.size}
                            onChange={handleInputChange}
                            error={errors.size}
                        />
                        </Grid>
                   
                    <Grid item xs={6}>
                        <Controls.Button
                        type="submit"
                        text="Submit"
                        onClick={handleSubmit}
                        />
                    </Grid> 
                        </>
                        : null
                    }
                    </>
                    :null
                }
                <Grid item xs={6}>
                    <Controls.Input
                        name="inflatedRate"
                        label="inflatedRate"
                        value={values.inflatedRate}
                        onChange={handleInputChange}
                        error={errors.inflatedRate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Button
                    type="submit"
                    text="Submit"
                    onClick={handleSubmitInflatedRate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        name="sequence"
                        label="sequence"
                        value={values.sequence}
                        onChange={handleInputChange}
                        error={errors.sequence}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Button
                    type="submit"
                    text="Submit"
                    onClick={handleSubmitSequence}
                    />
                </Grid>
                <Grid item xs={6}>
                More Info(PDF)<Switch id={recordForEdit.id} checked={recordForEdit.moreinfo ? true : false} onChange={handleSwitchProductMoreInfo} color="primary"/>
                </Grid>
                <Grid item xs={6}>
                    <input multiple={false}  type="file"  accept="application/pdf" onChange={handleUploadProductPdf} name={recordForEdit.docno} id={recordForEdit.id} />
                </Grid>
             
            </Grid>
        </Form>
    )

}