import React, { useState,useEffect } from 'react'
import { makeStyles } from "@material-ui/core";
import axios from 'axios';

export function useForm(
    initialFValues,
    initialUserDetails,
    initialcourierdetails,
    validateOnChange = false,
    validate,
    validateUserDetails,
    validatecourierTobeArranged,
    setNull) {

    

    const [values, setValues] = useState(initialFValues);
    const [data, setUserData] = useState(initialUserDetails);
    const [courierTobeArranged, setCourierTobeArranged] = useState(initialcourierdetails);
    const [errors, setErrors] = useState({});
    const [userError,setUserError]= useState({});
    const [courierError,setcourierError]= useState({});

    const [country,setCountry]= useState([]);
    const [state,setState]= useState([]);
    const [brands,setBrands]=useState([]);
    const [city,setCity]= useState([]);
    useEffect( () => {
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        // axios.get(`/demoData`,config).then(
        //   response =>{
        //     setValues(response.data[0]);
        //   }
        // )
        // .catch(
        //   error =>{
        //     alert("Technical Error !!!");
        //   }
        // );

        axios.all([axios.get(`countryData/`,config), axios.get('stateData',config), axios.get(`/demoData`,config), axios.get('getBrands',config)]).then(axios.spread((...responses) => {
            const responseOne = responses[0]
            const responseTwo = responses[1]
            const responseThree=responses[2]
            const responseFour=responses[3]
            // console.log(responseOne.data);
            // console.log(responseTwo.data);
            setCountry(responseOne.data);
            setState(responseTwo.data);
            setBrands(responseFour.data);
            setValues(responseThree.data[0]);
            }))
            .catch(errors => {
                alert("Technical Error !!!");
            });
    },[]);

    const handleInputChange = e => {
        const { name, value } = e.target
        if (validateOnChange)
            validate({ [name]: value })
            setValues({
                ...values,
                [name]: value
            })
    }

    const handleInputChangeforBrand = (e) =>{
        const { name, value } = e.target
        const parent_cd="parent_cd"
        const bvuser_cd="bvuser_cd"
        const bchk_pwd="bchk_pwd"
        const username=localStorage.getItem('tempUsername')
        setValues({
            ...values,
            [bvuser_cd]:username,
            [bchk_pwd]: username,
            [parent_cd]:value,
            [name]: value
        })
        if (validateOnChange){
            validate({ [name]: value })
        }     
    }

    const handleInputChangeforAccountName = (e) =>{
        const { name, value } = e.target
        const ac_name ="ac_name"
        if(value.length>30){
            setValues({
                ...values,
            })
        }else{
            setValues({
                ...values,
                [ac_name]:value,
                [name]: value
            })
        }
        if (validateOnChange)
            validate({ [name]: value })
    }

    const handleInputChangefroAddress =e=> {
        const { name, value } = e.target
        if (validateOnChange)
            validate({ [name]: value })
            if(value.length>30){
                setValues({
                    ...values,
                })
            }else{
                setValues({
                    ...values,
                    [name]: value
                })
            }  
    }

    const handleInputChangeforCountry = e =>{
        const { name, value } = e.target
        const cur= 'currency';
        const country_nm='country_nm';
        const result = country.find(item => item.ac_code==value);
        console.log(result);
        // setValues({
        //     ...values,
        //     [name]: value
        // })
        if(value == "I001"){
            setValues({
                ...values,
                [cur]: "0010",
                [country_nm]:result.ac_name,
                [name]: value
            })
            if (validateOnChange){
                validate({ [name]: value })
            }
        }else if(value == "U002"){
            setValues({
                ...values,
                [cur]: "002",
                [country_nm]:result.ac_name,
                [name]: value
            })
            if (validateOnChange){
                validate({ [name]: value })
            }
        }else{
            setValues({
                ...values,
                [cur]: "003",
                [country_nm]:result.ac_name,
                [name]: value
            })
            if (validateOnChange){
                validate({ [name]: value })
            }
        }
    }
    const handleInputChangeforState = e => {
        const { name, value } = e.target;
        const result = state.find(item => item.ac_code==value);
        console.log(result);
        const state_nm='state_nm';

        setValues({
            ...values,
            [name]: value,
            [state_nm]:result.ac_name
        })
        if (validateOnChange){
            validate({ [name]: value })
        }
       
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        axios.get(`cityData/${value}`,config).then(
            response =>{
                setCity(response.data);
                console.log(response.data);
            }
        )
        .catch(error =>{
            alert("City Is Not Awailable Select None");
        })
    }

    const handleInputChangeforCity = (e)=>{
        const { name, value } = e.target
        const result = city.find(item => item.ac_code==value);
        // console.log(result);
        const city_nm='city_nm';
        if (validateOnChange)
            validate({ [name]: value })
            setValues({
                ...values,
                [name]: value,
                [city_nm]:result.ac_name
            })
    }

    const handleInputChangeforTel_no = e =>{
        const { name, value } = e.target
        if (validateOnChange)
            validate({ [name]: value })
            if(value.length>30){
                setValues({
                    ...values,
                })
            }else{
                setValues({
                    ...values,
                    [name]: value
                })
            } 
    }

    const InputEvent = (event) => {
        const { name, value } = event.target;
        setUserData({
            ...data,
            [name]: value
        })
        if (validateOnChange)
            validateUserDetails({ [name]: value })
        
    }

    const handlecourierTobeArranged = (event) => {
        const { name, value } = event.target;
        setCourierTobeArranged({
            ...courierTobeArranged,
            [name]: value
        })
        if (validateOnChange)
            validatecourierTobeArranged({ [name]: value })
        
    }

    const handleInputChangeforCourier_yn = e =>{
        const { name, value } = e.target;
        let ac_no ='ac_no';
        let bill_code ='bill_code';
        if (validateOnChange)
            validate({ [name]: value })
            
            if(value=='Yes'){
                setValues({
                    ...values,
                    [ac_no]:'',
                    [bill_code]:'',
                    [name]: value,
                })
            }else{
                setValues({
                    ...values,
                    [name]: value
                })
            }
    }

    const handleInputChangeforBill_code = e =>{
        const { name, value } = e.target
        if (validateOnChange)
            validate({ [name]: value })
            if(value.length>30){
                setValues({
                    ...values,
                })
            }else{
                setValues({
                    ...values,
                    [name]: value
                })
            }
    }
    
    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})  
    }

    const resetUserData = () => {
        setUserData(initialUserDetails);
        setUserError({})
    }
    const resetCourierTobeArranged = () => {
        setCourierTobeArranged(initialcourierdetails);
        setcourierError({})
    }

    return {
        values,
        data,
        courierTobeArranged,
        country,
        state,
        city,
        brands,
        setValues,
        setUserData,
        setCourierTobeArranged,
        setCountry,
        setState,
        setCity,
        setBrands,
        errors,
        userError,
        courierError,
        setErrors,
        setUserError,
        setcourierError,
        handleInputChange,
        handleInputChangeforBrand,
        handleInputChangeforAccountName,
        handleInputChangefroAddress,
        handleInputChangeforCountry,
        handleInputChangeforState,
        handleInputChangeforCity,
        handleInputChangeforTel_no,
        InputEvent,
        handleInputChangeforCourier_yn,
        handleInputChangeforBill_code,
        resetForm,
        resetUserData,
        resetCourierTobeArranged,


    }
}


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

