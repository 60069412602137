import CartActionTypes from './cart.types';

export const toggleCartHidden = () => ({
  type: CartActionTypes.TOGGLE_CART_HIDDEN
});

// export const toggleHeaderHidden = () => ({
//   type: CartActionTypes.TOGGLE_HEADER_HIDDEN
// });

export const addItem = val => ({
  type: CartActionTypes.ADD_ITEM,
  payload: val
});

export const multiplyQuantity = (val) => ({
  type: CartActionTypes.MULTIPLY_QUANTITY,
  payload: val
});

export const updateQuantity = (val) => ({
  type: CartActionTypes.UPDATE_QUANTITY,
  payload: val
});

export const removeItem = item => ({
  type: CartActionTypes.REMOVE_ITEM,
  payload: item
});

export const clearItemFromCart = item => ({
  type: CartActionTypes.CLEAR_ITEM_FROM_CART,
  payload: item
});

export const clearCart = () => ({
  type: CartActionTypes.CLEAR_CART
});

export const fetchCartDetails = () => ({
  type: CartActionTypes.FETCH_CART_DETAILS
});

export const updateCorefNo = item => ({
type: CartActionTypes.UPDATE_COREFNO,
payload: item
});

export const updateRateIntoInflatedRate = () => ({
  type: CartActionTypes.UPDATERATEINTOINFLATEDRATE
});