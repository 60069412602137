import Axios from "axios";
import React,  {useLayoutEffect} from "react";
import { useState } from "react";
import { profomaInvoiceCreation } from "./InvoiceCreation";

const DownloadLinkInvoice = () => {
    const[msg, setMsg] = useState("Download in progress !!!!")
    useLayoutEffect( async()=>{
        let link = window.location.href;
        if(link?.includes("profomaInvoice/download/")) {
            let linkArray = link.split("profomaInvoice/download/");
            if(linkArray[1]) {
                let data = {
                    "orderId": linkArray[1]
                };
                await Axios.post(`downloadPI`,data).then(
                    res => {
                        if(res && res.data) {
                            const deliveryAddress = {
                                acname: res.data.username,
                                acnam0e: res.data.acname,
                                acaddr1: res.data.acaddr1,
                                acaddr2: res.data.acaddr2,
                                acaddr3: res.data.acaddr3,
                                acaddr4: res.data.acaddr4,
                                citynm: res.data.citynm,
                                statenm: res.data.statenm,
                                countrynm: res.data.countrynm,
                                zip: res.data.zip
                            };
                            profomaInvoiceCreation(res.data, deliveryAddress);
                            setTimeout(() => {
                                setMsg("Downloaded successfully !!!!");
                            }, 2000);
                        }
                    }).catch(()=> {
                        setMsg("Downloaded unsuccessfully !!!!");
                    });
            }
        }
    },[])

    return (msg ? <h4 className="">{msg}</h4> : null);
}

export default DownloadLinkInvoice;