import ShopActionTypes from './shop.types';
import axios from 'axios';
import shopData from '../../pages/shop/shop.data';


export const setShoppingData= products => ({
type: ShopActionTypes.SET_SHOPPIND_DATA,
payload: products
})

// export const filterProductsByGroup = (products, groupname) => ({
//   type: ShopActionTypes.FILTER_PRODUCTS_BY_GROUP,
//   payload: {
//       groupname: groupname,
//       items: groupname === "" ? products : products.filter(product => product.groupname === groupname)
//   }
// });

export const fetchCollectionsStart = () => ({
    type: ShopActionTypes.FETCH_COLLECTIONS_START
  });
  
  export const fetchCollectionsSuccess = collectionsMap => ({
    type: ShopActionTypes.FETCH_COLLECTIONS_SUCCESS,
    payload: collectionsMap
  });
  
  export const fetchCollectionsFailure = errorMessage => ({
    type: ShopActionTypes.FETCH_COLLECTIONS_FAILURE,
    payload: errorMessage
  });
  
  export const fetchCollectionsStartAsync = () => {
    return dispatch => {
    //   const collectionRef = firestore.collection('collections');
      dispatch(fetchCollectionsStart());
  
      const token= localStorage.getItem('token');
      if(token!==null){
        const config ={
          headers:{ Authorization: 'Bearer ' + token }
        }
  
        axios.get(`products`,config)
        .then(response => {
        setShoppingData(response.data);
        dispatch(fetchCollectionsSuccess(response.data));
        })
        .catch(errors => {
            console.log(errors);
            localStorage.clear();
        });
      } 
    };
  };
  