import ShopActionTypes from './shop.types';
const INITIAL_STATE = {
  shoppingData: [],
  collections: null,
  isFetching: false,
  errorMessage: undefined
  };
const shopReducer = (state = INITIAL_STATE ,action) =>{
    switch (action.type) {
        case ShopActionTypes.SET_SHOPPIND_DATA:
          return {
            ...state,
            shoppingData: action.payload
          };
          // case ShopActionTypes.FILTER_PRODUCTS_BY_GROUP:
          //   return {
          //     ...state,
          //     filteredItems: action.payload.items
          // };
          case ShopActionTypes.FETCH_COLLECTIONS_START:
            return {
              ...state,
              isFetching: true
            };
          case ShopActionTypes.FETCH_COLLECTIONS_SUCCESS:
            return {
              ...state,
              isFetching: false,
              shoppingData: action.payload
              // collections: action.payload
            };
          case ShopActionTypes.FETCH_COLLECTIONS_FAILURE:
            return {
              ...state,
              isFetching: false,
              errorMessage: action.payload
            };
        default:
           return state;
        }

};
export default shopReducer;

