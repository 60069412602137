const CartActionTypes = {
    TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
    ADD_ITEM: 'ADD_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
    MULTIPLY_QUANTITY: 'MULTIPLY_QUANTITY',
    UPDATE_QUANTITY: 'UPDATE_QUANTITY',
    CLEAR_CART: 'CLEAR_CART',
    UPDATE_COREFNO: 'UPDATE_COREFNO',
    UPDATERATEINTOINFLATEDRATE: 'UPDATERATEINTOINFLATEDRATE',
    FETCH_CART_DETAILS:"FETCH_CART_DETAILS"

    // TOGGLE_HEADER_HIDDEN: 'TOGGLE_HEADER_HIDDEN'
  };
  
  export default CartActionTypes;