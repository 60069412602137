
  import React, { useState,useEffect } from "react";
  import { Document, Page,pdfjs } from "react-pdf";
  import about_us from '../../assets/pdf/about_us.pdf';
  import contact_us from '../../assets/pdf/contact_us.pdf';
  import privacy_statement from '../../assets/pdf/privacy_statement.pdf';
  import refund_policy from '../../assets/pdf/refund_policy.pdf';
  import shipping_policy from '../../assets/pdf/shipping_policy.pdf';
  import terms_of_service from '../../assets/pdf/terms_of_service.pdf';
import { Button } from "@material-ui/core";
  
  export default function MyPdfViewer() {
    const [numPages, setNumPages] = useState(null);
    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;},[]);
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
   
    const [showPdf , setShowPdf] = useState(1);
  
    return (
      <>
        <div className="d-flex">
          <Button onClick={()=>{setShowPdf(1)}} variant="outlined">about_us</Button>
          <Button onClick={()=>{setShowPdf(2)}} variant="outlined">contact_us</Button>
          <Button onClick={()=>{setShowPdf(3)}} variant="outlined">privacy_statement</Button>
          <Button onClick={()=>{setShowPdf(4)}} variant="outlined">refund_policy</Button>
          <Button onClick={()=>{setShowPdf(5)}} variant="outlined">shipping_policy</Button>
          <Button onClick={()=>{setShowPdf(6)}} variant="outlined">terms_of_service</Button>
        </div>
      <Document
      file={showPdf === 1 ? about_us : showPdf === 2 ? contact_us : showPdf === 3 ? privacy_statement : showPdf === 4 ? refund_policy : showPdf === 5 ? shipping_policy : showPdf === 6 ? terms_of_service : about_us}
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
      </>
    );
  }