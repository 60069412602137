import React,{useState} from 'react';
import {connect} from 'react-redux';
import {setCurrentUser} from '../../redux/user/user.actions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { NavLink} from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vasantslabels.com/">
        vasantslabels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = ({setUser,setSpinnerActive,history,url,setCurrentUser}) => {
  const classes = useStyles();
  const [isOpenSpinner,setOpenSpinner]=useState(true);
  const [data, setLoginData] = useState({
    username: "",
    password: "",
  });

  const formSubmit = async(e) => {
        e.preventDefault();
        const logindata={
          username: data.username,
          password: data.password
        }
        await axios.post(`${url}`,logindata).then(
          response => {
            console.log(response);
            if(url==="authenticate"){
                localStorage.setItem('token',response.data.token);
                setUser(response.data);
                setSpinnerActive(true);
                setOpenSpinner(false);
                history.push('/');
            }else{
              
              if(response.data.status){
                localStorage.setItem('token',response.data.token);
                localStorage.setItem('tempUsername',response.data.username);
                history.push('/signin/brandBuyerForm');
              }else{
                alert("You have allready submitted response")
                return;
              }
                
            }
          }
        )
        .catch(error => {
          alert("Username or Password Is Incorrect Please Login Again")
        });

      };


  const InputEvent = (event) => {
    const { name, value } = event.target;

    setLoginData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={formSubmit}>
       
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="username"
            label="login Id"
            name="username"
            value={data.username}
            onChange={InputEvent}
            autoFocus
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={data.password}
            onChange={InputEvent}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        
          <Grid container>
            <Grid item xs>
              <NavLink to="/forgotPassword" variant="body2">
                Forgot password?
              </NavLink>
            </Grid>
            <Grid item>
                
                  {
                    url==="authenticate" ? 
                    ( 
                    <NavLink to="/signup/user" variant="body2">
                    {"Don't have an account? Sign Up"}
                    </NavLink>
                    )
                    : null
                }

                
                {
                    url==="authenticateBuyer" ?
                    (
                    <NavLink to="/signup/brandBuyer" variant="body2">
                    {"Don't have an account? Sign Up"}
                    </NavLink>
                    ): null
                }
            </Grid>
          </Grid>
        </form>
  
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

const mapDispatchToProps = dispatch =>({
  setCurrentUser: user =>dispatch(setCurrentUser(user))
});

export default withRouter(connect(
  null,
  mapDispatchToProps
)(SignIn));