import React, {useState, useEffect,Suspense,lazy  } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import axios from "axios";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import {connect} from 'react-redux';
import {setCurrentUser} from './redux/user/user.actions';
import {setShoppingData} from './redux/shop/shop.action';
import {selectCurrentUser} from './redux/user/user.selectors';
import {selectShoppingData} from './redux/shop/shop.selectors';
import {selectCartTotal} from './redux/cart/cart.selectors';

import Service from "./Service";
import About from "./About";
import Contact from "./Contact";
import Navbar from "./Navbar";

import Footer from "./Footer";
import MyPdfViewer from './components/TearmsAndConditionPolicy/MyPdfViewer';

import SigninAdmin from './components/sign-in/sign-in.component.Admin';
import SignIn from "./components/sign-in/sign-in.component";
import SignUp from "./components/sign-up/sign-up.component";


import ForgotPassword from "./components/forgot-password/ForgotPassword";
import BrandBuyer from "./pages/brandBuyerForm/BrandBuyer";
import Dashboard from './components/Admin_DashBoard/Dashboard';
import {createStructuredSelector} from 'reselect';
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import Spinner from "./components/spinner/spinner.component";
import { addItem, fetchCartDetails } from './redux/cart/cart.actions';
import DownloadLinkInvoice from './components/InvoiceCreation/DownloadLinkInvoice';
import Popup from './components/Popup';
const Checkout = lazy(() => import('./pages/checkout/Checkout'));
const CheckoutPage = lazy(() => import('./pages/checkout/checkout.component'));
const Home = lazy(() => import('./Home'));
const Main = lazy(() => import('./pages/Main_Home/Main'));
const ProductDetailPage = lazy(()=> import('./pages/productDetailpage/productDetailPage.component'));
const OrderConfirm = lazy(() => import( './pages/Order/OrderConfirm'));
const OrderDetail =lazy(() => import( './pages/Order/OrderDetail'));
const ProfilePage = lazy(() => import('./pages/profile/profile.component'));

const App = ({setCurrentUser,currentUser,setShoppingData,addItem,shoppingData,total}) => {
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [orderConformation, setOrderConformation] = useState(false);
  const [cityNotApplyInflation,setCity]=useState(["V018","V001","T040","C038","C031","B148","B001"]);
  const[user,setUser]=useState(null);
 
  const [role, setRole]= useState(null);
  const [infletedRateApply,setinfletedRateApply]=useState(true);
  const [openPopup, setOpenPopup] = useState(false);
 
  useEffect( () => {
    getStartData();
  },[user]); 
  
  const fetchCartDetails = async() => {
    const config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.get(`endUser/cartDetails`,config)
      .then(response => {
        if(response && response.data) {
          response.data.forEach(item=> {
            addItem(item);
          });
        }
      }).catch(e=> {
        console.log(e);
        return [];
      });
  }
  const getStartData= async()=>{
    const token= localStorage.getItem('token');
  // console.log(userName);
  if(token!==null){
        const config ={
          headers:{ Authorization: 'Bearer '+token}
        }
        await axios.all([axios.get(`user`,config)]).then(axios.spread((...responses) => {
        const responseOne = responses[0]
        if(!responseOne) {
          setShoppingData([]);
        }
        // const responseTwo = responses[1]
        setCurrentUser(responseOne.data);
        if(responseOne.data.acmastDetails.find(acmast => cityNotApplyInflation.includes(acmast.city))) {
          setinfletedRateApply(false);
        }
        // setShoppingData(responseTwo.data);
        setRole(responseOne.data.roles[0].name);
        setSpinnerActive(false);
       fetchCartDetails();
        }))
        .catch(errors => {
        // console.log(errors);
        setShoppingData([]);
        localStorage.clear();
        setSpinnerActive(false);
       
        });                                         
   } else {
    setSpinnerActive(false);
   }
  }
  
  const checkToken=()=>{
    const token= localStorage.getItem('token');
    if(token){
      return true;
    }else{
      return false;
    }
  }

  return(
    <>
      {/* {(currentUser!==null) ? (currentUser.roles[0].name!='Admin'? <Header />: null) : <Navbar /> } */}
      
      {
        spinnerActive ? 
        <Spinner/>
        :
        <>
        {(currentUser!==null) ? null : <Navbar />}
        <Switch>
        <ErrorBoundary>
        <Suspense fallback={<Spinner/>}>
        <Route exact path="/" render={() => (currentUser!==null) ? (<Main infletedRateApply={infletedRateApply}/>) : (<Home/>)}/>
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route exact path="/checkoutOrder" render={() => (total>0 || orderConformation) ? <Checkout orderConformation={orderConformation} setOrderConformation={setOrderConformation}/> : <Redirect to="/" /> } />
        <Route exact path="/checkout/orderConfirm"  render={(props) => <OrderConfirm {...props}/> }  />
        <Route exact path="/orders" component={OrderDetail} />
        {/* <Route exact path="/checkout" render={() => currentUser.username!==null ?  <CheckoutPage/> : <Redirect to="/" /> } /> */}
        <Route exact path="/productDetail"  render={(props) =>  (currentUser!==null && currentUser.username!==null) ? <ProductDetailPage {...props}/> : <Redirect to="/" /> }  />
       
        {/* // <Route exact path="/" component={Main} /> */}
        {/* <Route exact path="/" component={()=> <Home  user={user} />} /> */}
        {/* <Route exact path="/Home" component={Home} /> */}
        <Route exact path="/about" component={About} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        {/* <Route exact path="/productsInfo" component={ProductsInfo} /> */}

        {/* <Route exact path={`/adsignin/`} render={() => currentUser==null ? <SignIn url="authenticate" setUser={setUser}/>: <Redirect to="/" />} /> */}
        <Route exact path={`/signin/adAdmin`} render={() => currentUser==null ? <SigninAdmin setUser={setUser} setSpinnerActive={setSpinnerActive}/>: <Redirect to="/" />} />
        <Route exact path={`/signin/user`} render={() => currentUser==null ? <SignIn url="authenticate" setUser={setUser} setSpinnerActive={setSpinnerActive}/>: <Redirect to="/" />} />
        <Route exact path={`/signin/brandBuyer`} render={() => currentUser==null ? <SignIn url="authenticateBuyer" />: <Redirect to="/" />}/>
        <Route exact path="/signin/brandBuyerForm" render={() =>(checkToken())? <BrandBuyer setUser={setUser} setSpinnerActive={setSpinnerActive}/> : null }/>
        <Route exact path={`/signup/user`} render={() => currentUser==null ? <SignUp url="registerUser"/>: <Redirect to="/" />} />
        <Route exact path={`/signup/brandBuyer`} render={() => currentUser==null ? <SignUp url="registerBuyer"/>: <Redirect to="/" />} />
        {/* <Route exact path="/productDetail"  render={(props) => currentUser!==null ? <ProductDetailPage {...props}/> : <Redirect to="/" /> }  /> */}
        <Route  path="/adDashboard" component={Dashboard} />
        <Route  path="/profile" component={ProfilePage} />
        <Route  path="/profomaInvoice/download/:id" component={DownloadLinkInvoice} />
        {/* <Redirect to="/" /> */}
        </Suspense>
        </ErrorBoundary>
      </Switch>
      </>
      
      }
      <Popup
        title=""
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <MyPdfViewer />
      </Popup>
     <Footer setOpenPopup={setOpenPopup}/>
    </>
  );
};

const mapSateToProps = createStructuredSelector ({
  currentUser: selectCurrentUser,
  shoppingData: selectShoppingData,
  total: selectCartTotal,
});

const mapDispatchToProps = dispatch =>({
  addItem: item => dispatch(addItem(item)),
  setCurrentUser: user =>dispatch(setCurrentUser(user)),
  setShoppingData:products => dispatch(setShoppingData(products)),
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
  )(App);

