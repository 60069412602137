import React from 'react';


import {
  CartItemContainer,
  ItemDetailsContainer,
  CartItemImage
} from './cart-item.styles';

// const CartItem = ({ item: { imgsrc, price, title, quantity } }) => (
//   <div className='cart-item'>
//     <img src={imgsrc} alt='item' />
//     <div className='item-details'>
//       <span className='name'>{title}</span>
//       <span className='price'>
//         {quantity} x ${price}
//       </span>
//     </div>
//   </div>
// );
const CartItem = ({ item: { images, rate, docno, quantity } }) => (
  <CartItemContainer>
    {
      (images)? <CartItemImage src={`data:image/png;base64,${images[0]}`} alt='item' />:null
    }
    <ItemDetailsContainer>
      <span>{docno}</span>
      <span >
        {quantity} x ₹{rate/100}
      </span>
    </ItemDetailsContainer>
  </CartItemContainer>
);
// const CartItem = ({ item: { images, rate, docno, qty } }) => (
//   <div className='cart-item'>
//     <img src={`data:image/png;base64,${images[0]}`}  alt='item' />
//     <div className='item-details'>
//       <span className='name'>{docno}</span>
//       <span className='price'>
//         {qty} x ${rate}
//       </span>
//     </div>
//   </div>
// );

export default CartItem;