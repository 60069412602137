import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';


// axios.defaults.baseURL='http://localhost:8080/';
// axios.defaults.baseURL='https://brandcare.biz/zuulgatwayproxy/';
// axios.defaults.baseURL='http://103.195.246.60/onlineOrderingApp/';
axios.defaults.baseURL='https://vlpl.site/onlineOrderingApp/';
// axios.defaults.baseURL='http://36.255.254.195/onlineOrderingApp/';
// axios.defaults.baseURL='https://vlpl.site/zuulgatwayproxy/';
// axios.defaults.headers.common['Authorization']='Bearer ' + localStorage.getItem('token');
ReactDOM.render(
  <>
  <Provider store={store}>
    <BrowserRouter>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
    </BrowserRouter>
  </Provider>
  </>,
  document.getElementById("root")
);
