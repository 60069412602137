import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import Select2 from "./Select2";
import Select3 from "./Select3";
import Select4 from "./Select4";
import ActionButton from "./ActionButton";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    Select2,
    Select3,
    Select4,
    ActionButton

}

export default Controls;