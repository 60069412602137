import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';
import Header from '../../Header';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog, DialogTitle, DialogContent, Paper, makeStyles, TableHead, TableBody, TableRow, TableCell, IconButton, Collapse, Box, Typography, Table, CardMedia, CardContent, Button, Grid, TableFooter } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
// import {useTable} from '../../components/useTable';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// import {connect} from 'react-redux';
// import {createStructuredSelector} from 'reselect';
// import { selectCurrentUser } from '../../redux/user/user.selectors';
import * as InvoiceCreation from '../../InvoiceCreation/InvoiceCreation';

import Spinner from "../../spinner/spinner.component";
import Controls from '../../controls/Controls';
import { Edit, Save } from '@material-ui/icons';
import Notification from '../../Notification';
import ConfirmDialog from '../../ConfirmDialog';
import {AwaitingOrdeStatus, confirmStatus} from '../../../constants/VlplConstants';


const styles = {

    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },

};



const useRowStyles = makeStyles(theme =>({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    media: {
        maxWidth: 280,
        height: 196,
    },
    content: {
        height: 90,
        maxWidth: 280,
    },
    total: {
        backgroundColor: "#c0c0c0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    },
    openRowData : {
        backgroundColor: '#a4d9ea'
    },
    openRowDataAwaiting : {
        backgroundColor: '#ffffb2'
    },
    infoMessage : {
        textAlign: 'center',
        color : 'chocolate'
    },
    saveButton : {
        marginRight : '10px'
    }
}));

const RowData = (props) => {
    const { rowData} = props;
    const classes = useRowStyles();
    // let [rowData, setRowData] = useState(undefined);
    // useEffect(() => {
    //     getOrderInfo();
    // }, [])

    const getOrderInfo = async () => {
        if (!rowData) {
            // const token = localStorage.getItem('token');
            // let config = {
            //     headers: { Authorization: 'Bearer ' + token }
            // }
            // await axios.get(`orderInfo/${props.rowData.id}`, config).then(
            //     response => {
            //         setRowData({ ...response.data });
            //         // setRowData((preVal) => {
            //         //     return {
            //         //       ...preVal,
            //         //       ["orderitems"]: response.data.orderitems,
            //         //     };
            //         //   });
            //         //   setOpen(true);
            //         // setData(JSON.stringify(response.data));
            //         // setTimeout(() => {
            //         //     setOpen(true);
            //         // }, 200);
            //     })
            //     .catch(errors => {
            //         // setOpen(false)
            //         alert("order items not found !!! ERROR !!!")
            //     });
        }
    }
    const getRounOffValue = (val) => {
        let valArray = val.toString().split(".");
        if (valArray[1]) {
            return valArray[0] + "." + valArray[1].substring(0, 2);
        } else {
            return valArray[0];
        }
    }
    const handleDownloadProfoma = () => {
        const deliveryAddress = {
            acname: rowData.acname,
            acaddr1: rowData.acaddr1,
            acaddr2: rowData.acaddr2,
            acaddr3: rowData.acaddr3,
            acaddr4: rowData.acaddr4,
            citynm: rowData.citynm,
            statenm: rowData.statenm,
            countrynm: rowData.countrynm,
            zip: rowData.zip
        };
        InvoiceCreation.profomaInvoiceCreation(rowData, deliveryAddress);
    }
    return (

        rowData ?
            <>
                <Typography variant="h6" gutterBottom component="div">
                    Order Details
                </Typography>
                <Table size="small" aria-label="purchases">
                    <TableBody>
                        
                            {/* { rowData?.orderitems?.map((val, ind) => (
                                <TableRow >
                                    <TableCell key={ind} align="right">
                                        <CardMedia
                                            className={classes.media}
                                            image={`data:image/png;base64,${val.image}`}
                                            title={val.docno}
                                        />
                                        <CardContent className={classes.content}>
                                            <Typography variant="body2" color="textSecondary" >
                                                {val.ordinst1}
                                            </Typography>
                                            {
                                                val.selectedSize ?
                                                    <Typography>Size: {val.selectedSize}</Typography> : null
                                            }
                                            <Typography>
                                                {val.rate} X {val.quantity}
                                            </Typography>
                                        </CardContent>
                                    </TableCell>
                                
                                </TableRow>
                                ))
                            } */}
                        
                        <TableRow>
                            <TableCell align="left"><DescriptionIcon fontSize="large" /><Button color="primary" onClick={handleDownloadProfoma}>Download</Button> </TableCell>
                            <TableCell align="right">Order Status: {(rowData?.status === AwaitingOrdeStatus) ? AwaitingOrdeStatus : confirmStatus}</TableCell>
                            <TableCell align="right"><Edit fontSize="large" /><Button color="primary" onClick={()=>{props.openPopupForRowData(rowData)}}>Edit Invoice</Button> </TableCell>
                            { rowData?.status === AwaitingOrdeStatus &&
                                <TableCell align="right"><Save fontSize="large" /><Button color="primary" onClick={()=>{props.changeOrderStatus(rowData)}}>Confirm Changes</Button> </TableCell>
                            }
                            
                        </TableRow>
                    </TableBody>
                </Table>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            {/* <TableCell align="right">Final-Rate</TableCell> */}
                            <TableCell align="right">Total</TableCell>
                            <TableCell align="right">Tax</TableCell>
                            <TableCell align="right">Sub-Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rowData?.orderitems?.map((val, ind) => (
                                <TableRow key={ind}>
                                    <TableCell component="th" scope="row">{val.docno}</TableCell>
                                    <TableCell>{val.selectedSize}</TableCell>
                                    <TableCell >{val.rate}</TableCell>
                                    <TableCell align="right">{val.quantity}</TableCell>
                                    {/* <TableCell align="right">{val.finalrate}</TableCell> */}
                                    <TableCell align="right">{getRounOffValue(val.finalRate)}</TableCell>
                                    <TableCell align="right">{rowData.itemTaxType.length === 2 ? <><Typography>sgst:{getRounOffValue(val.sgst)}</Typography><Typography>cgst:{getRounOffValue(val.cgst)}</Typography></> : <><Typography>igst:{getRounOffValue(val.igst)}</Typography></>}</TableCell>
                                    <TableCell align="right">{getRounOffValue(val.finalRateWithTax)}</TableCell>
                                </TableRow>
                            ))
                        }
                        <TableRow className={classes.total}>
                            <TableCell component="th" scope="row"><Typography>Total :</Typography></TableCell>
                            <TableCell align="right"><Typography>{getRounOffValue(rowData.total)}</Typography></TableCell>
                        </TableRow>
                        <TableRow className={classes.total}>
                            <TableCell component="th" scope="row"><Typography>Tax :</Typography></TableCell>
                            <TableCell align="right"><Typography>{getRounOffValue(rowData.subTotal - rowData.total)}</Typography></TableCell>
                        </TableRow>
                        <TableRow className={classes.total}>
                            <TableCell component="th" scope="row"><Typography>Sub-Total :</Typography></TableCell>
                            <TableCell align="right"><Typography>{Math.round(rowData.subTotal)}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </> : null
    )
}

const Row = (props) => {
    const { rowData, changeOrderData } = props;
    // const [data, setData] = React.useState(undefined);
    const [open, setOpen] = React.useState(false);
    const [spinnerActive, setSpinnerActive] = useState(false);

    // useEffect(() => {
    //     if (data) {
    //         // addOrderDetails(data);
    //         setOpen(true);
    //         // setRowData({...rowData});
    //         // setOpen(true);
    //         // setTimeout(() => {
    //         //     addOrderDetails(data);
    //         //     setOpen(true);
    //         //     console.log(rowData);
    //         // }, 1000);
    //     }
    // }, [data])

    const classes = useRowStyles();

    const handleClick = async () => {
        if (!open) {
            setSpinnerActive(true);
            const token = localStorage.getItem('token');
            let config = {
                headers: { Authorization: 'Bearer ' + token }
            }
            await axios.get(`orderInfo/${rowData.id}`, config).then(
                response => {
                    changeOrderData({ ...response.data });
                    setOpen(true);
                    setSpinnerActive(false);
                    // setRowData((preVal) => {
                    //     return {
                    //       ...preVal,
                    //       ["orderitems"]: response.data.orderitems,
                    //     };
                    //   });
                    //   setOpen(true);
                    // setData(JSON.stringify(response.data));
                    // setTimeout(() => {
                    //     setOpen(true);
                    // }, 200);
                })
                .catch(errors => {
                    setOpen(false);
                    setSpinnerActive(false);
                    alert("order items not found !!! ERROR !!!")
                });
        } else {
            setOpen(false);
        }

    }

    // const getRounOffValue = (val) => {
    //     let valArray = val.toString().split(".");
    //     if (valArray[1]) {
    //         return valArray[0] + "." + valArray[1].substring(0, 2);
    //     } else {
    //         return valArray[0];
    //     }
    // }
    // const handleDownloadProfoma = () => {
    //     const deliveryAddress = {
    //         acname: rowData.acname,
    //         acaddr1: rowData.acaddr1,
    //         acaddr2: rowData.acaddr2,
    //         acaddr3: rowData.acaddr3,
    //         acaddr4: rowData.acaddr4,
    //         citynm: rowData.citynm,
    //         statenm: rowData.statenm,
    //         countrynm: rowData.countrynm,
    //         zip: rowData.zip
    //     };
    //     const userDetails = {
    //         email: rowData.email,
    //         mobileNo: rowData.mobileNo
    //     }
    //     InvoiceCreation.profomaInvoiceCreation(rowData, deliveryAddress, userDetails);
    // }

    return (
        <React.Fragment>
            {
            spinnerActive ?
            <Spinner />
            :
            <>
            <TableRow className={classes.root} onClick={() => handleClick()}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {rowData.createdAt}
                </TableCell>
                <TableCell align="right">{rowData.id}</TableCell>
                <TableCell align="right">{rowData.createdAt}</TableCell>
                {/* <TableCell align="right"></TableCell> */}
                <TableCell align="right">{rowData.subTotal}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} className={(rowData?.status === AwaitingOrdeStatus) ? classes.openRowDataAwaiting : classes.openRowData}>
                        {
                            open && <RowData rowData={rowData} openPopupForRowData={props.openPopupForRowData} changeOrderStatus={props.changeOrderStatus}/>
                        }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            </>
            }
            
        </React.Fragment>
    );
}

const ShoppingDetailDailogTable = (props) => {
    const classes = useRowStyles();
    const [spinnerActive, setSpinnerActive] = useState(true);
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [orders, setOrders] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedInvoice, setSelectInvoice] = useState({});
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    // const [userDetails,setUserDetails]=useState(null);
    // const pages = [10, 25];
    // const {
    //     TblContainer,
    //     TblHead,
    //     TblPagination,
    //     recordsAfterPagingAndSorting
    // } = useTable(orders, pages, null, filterFn);

    useEffect(() => {
        getOrders();
    }, []);

    const openPopupForRowData = (rowData) => {
        if (rowData) {
            console.log(rowData);
            let data = {...rowData};
            data?.orderitems?.forEach(obj=> {
                obj.editRate = obj.rate;
                obj.editQuantity = obj.quantity;
            });
            setSelectInvoice(data);
            setOpenPopup(true);
        }
    }

    const getOrders = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            let config = {
                headers: { Authorization: 'Bearer ' + token }
            }
            await axios.get(`orderDetails/${props.username}`, config).then(
                response => {
                    setOrders(response.data);
                    setSpinnerActive(false);
                    // console.log(response.data);
                })
                .catch(errors => {
                    setSpinnerActive(false);
                    localStorage.clear();
                    // history.push("/");
                });
        } else {
            setSpinnerActive(false);
            localStorage.clear();
            // history.push("/");
        }
    }

    const handleInputChange = e => {
        const { name, value, id } = e.target;
        console.log(id);
        let data = { ...selectedInvoice};
        if(data?.orderitems?.length) {
            let index = data?.orderitems?.findIndex((obj,index)=> index == id);
            if(index !== undefined && index !== -1) {
                if(name === 'rate') {
                    data.orderitems[index].editRate = value;
                } else if(name === 'quantity') {
                    data.orderitems[index].editQuantity = value;
                }
            }
            setSelectInvoice(data);
        }
        
    }

    const checkError = (value) => {
        if (value) {
            return "";
        } else {
            return "This field is required."
        }
    }

    const closePopup =()=> {
        setSelectInvoice({});
        setOpenPopup(false);
    }
    const saveChangesInInvoice =()=> {
        let changesInOrderItemsList = [];
        selectedInvoice?.orderitems?.forEach(obj=> {
            if (obj.editRate != obj.rate) {
                changesInOrderItemsList.push(obj);
            } else if(obj.editQuantity != obj.quantity) {
                changesInOrderItemsList.push(obj);
            }
        });
        if(changesInOrderItemsList?.length) {
            setConfirmDialog({
                isOpen: true,
                title: 'Do you want to change this invoice?',
                subTitle: "You can't undo this operation",
                onConfirm: () => { onconformSaveInvoiceChanges(changesInOrderItemsList); }
            });
        } else {
            setNotify({
                isOpen: true,
                message: 'Notice: No changes detected. You are attempting to save the record without making any modifications. Please make the necessary changes before saving.',
                type: 'info'
                });
        }
    }

    const onconformSaveInvoiceChanges = (changesInOrderItemsList) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        closePopup();
        const token = localStorage.getItem('token');
        if (token) {
            let config = {
                headers: { Authorization: 'Bearer ' + token }
            }
            setSpinnerActive(true);
            axios.post(`manageOrderController/changeInvoice`,selectedInvoice,config).then(
                response => {
                   console.log(response.data);
                    // console.log(response.data);
                    let orderData = [...orders];
                    let index = orderData?.findIndex(obj=> obj.id === selectedInvoice.id);
                    if(index !== undefined && index !== -1) {
                        orderData[index] = response.data;
                    }
                    setSelectInvoice(response.data);
                    setOrders(orderData);
                    setNotify({
                        isOpen: true,
                        message: 'Success: Invoice changes saved. The total pricing has been updated accordingly please check.',
                        type: 'success'
                    });
                    setSpinnerActive(false);
                })
                .catch(errors => {
                    setSpinnerActive(false);
                    // localStorage.clear();
                    // history.push("/");
                });
        } else {
            setSpinnerActive(false);
            // localStorage.clear();
            // history.push("/");
        }
    }

    const changeOrderData=(orderRes)=> {
        if (orderRes) {
            let orderData = [...orders];
            let index = orderData?.findIndex(obj => obj.id === orderRes.id);
            if (index !== undefined && index !== -1) {
                orderData[index] = orderRes;
            }
            setOrders(orderData);
        }
    }

    const onconformSaveChangeOrderStatus=(orderData)=> {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        closePopup();
        const token = localStorage.getItem('token');
        if (token) {
            let config = {
                headers: { Authorization: 'Bearer ' + token }
            }
            setSpinnerActive(true);
            axios.post(`manageOrderController/changeOrderStatus`,orderData,config).then(
                response => {
                   console.log(response.data);
                    if (response.data) {
                        let orderDataArray = [...orders];
                        let index = orderDataArray?.findIndex(obj => obj.id === orderData.id);
                        if (index !== undefined && index !== -1) {
                            orderDataArray[index].status = confirmStatus;
                        }
                        setOrders(orderDataArray);
                        setNotify({
                            isOpen: true,
                            message: 'Success: Invoice changes are confirmed please check !!!',
                            type: 'success'
                        });
                        setSpinnerActive(false);
                    }
                })
                .catch(errors => {
                    setSpinnerActive(false);
                });
        } else {
            setSpinnerActive(false);
        }
    }

    const changeOrderStatus=(orderData)=> {
        if(orderData) {
            setConfirmDialog({
                isOpen: true,
                title: 'Do you want to save invoice changes?',
                subTitle: "You can't undo this operation",
                onConfirm: () => { onconformSaveChangeOrderStatus(orderData); }
            });    
        }
    }
    // const addOrderDetails = (obj) =>{
    //     let orderData = [];
    //     orders.forEach(val=>{
    //         if(val.id == obj.id) {
    //             val = obj;
    //         }
    //         orderData.push(val);
    //     })
    //     setOrders([...orderData]);
    // }

    return (
        <>
            {/* <Header searchInput={false} /> */}
            {
                spinnerActive &&
                <Spinner />
            }
            <Card>
                <CardBody>
                    <Paper>
                        {
                            orders.length ?
                                <>
                                    {/* <TblContainer > */}
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Labels</TableCell>
                                                <TableCell align="right">Order No</TableCell>
                                                <TableCell align="right">Date</TableCell>
                                                <TableCell align="right">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orders.map((rowData, ind) => (
                                                <Row key={ind} ind={ind} changeOrderData={changeOrderData} changeOrderStatus={changeOrderStatus} rowData={rowData} username={props.username} openPopupForRowData={openPopupForRowData} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {/* </TblContainer> */}
                                    {/* <TblPagination /> */}
                                </> :
                                <Typography>Order Not Found</Typography>
                        }
                    </Paper>
                </CardBody>
            </Card>

            {
                openPopup
                &&
                <Dialog open={openPopup} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
                    <DialogTitle className={classes.dialogTitle}>
                        <div style={{ display: 'flex' }}>
                            <Typography align='left' variant="h6" component="div" style={{ flexGrow: 1 }}>
                                Change Invoice Details
                            </Typography>
                            <CloseIcon align='right' onClick={closePopup} />
                            {/* <Controls.ActionButton
                            color="secondary"
                            onClick={()=>{
                                setOpenPopup(false);
                                }}>
                            <CloseIcon />
                        </Controls.ActionButton> */}
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container>
                            <Grid className={classes.infoMessage} item xs={12}>You may change the rate of the item and the quantity and price will be calculated accordingly !</Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Size</TableCell>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            selectedInvoice?.orderitems?.map((val, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{val.docno}</TableCell>
                                                    <TableCell>{val.selectedSize}</TableCell>
                                                    <TableCell >
                                                        <Controls.Input
                                                            label="Rate"
                                                            name="rate"
                                                            type="number"
                                                            id={index}
                                                            value={val.editRate}
                                                            onChange={handleInputChange}
                                                            error={checkError(val.editRate)}
                                                        />
                                                    </TableCell>
                                                    <TableCell >
                                                        <Controls.Input
                                                            label="Quantity"
                                                            name="quantity"
                                                            type="number"
                                                            id={String(index)}
                                                            value={val.editQuantity}
                                                            onChange={handleInputChange}
                                                            error={checkError(val.editQuantity)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                                <div className='pt-2 text-end'>
                                    <Button variant="contained" className={classes.saveButton} onClick={saveChangesInInvoice}>Save Changes</Button>
                                    <Button variant="outlined" onClick={closePopup}>Cancel</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}

export default ShoppingDetailDailogTable;
