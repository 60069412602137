import CartActionTypes from './cart.types';
import { addItemToCart, removeItemFromCart, addItemToCartMultiplyQuantity,addItemToCartUpdateQuantity, calculateAndgetCartDetails, fetchCartDetails } from './cart.utils';
const INITIAL_STATE = {
  hidden: true,
  cartItems: [],
  corefno:"",
  // headerHidden: false
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.TOGGLE_CART_HIDDEN:
      return {
        ...state,
        hidden: !state.hidden
      };
    case CartActionTypes.ADD_ITEM:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload)
      };
    case CartActionTypes.MULTIPLY_QUANTITY:
      return {
        ...state,
        cartItems: addItemToCartMultiplyQuantity(state.cartItems, action.payload)
      };
    case CartActionTypes.UPDATE_QUANTITY:
      return {
        ...state,
        cartItems: addItemToCartUpdateQuantity(state.cartItems, action.payload)
      };
    case CartActionTypes.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload)
      };
    case CartActionTypes.CLEAR_ITEM_FROM_CART:
      if(action.payload.ordertype.toLowerCase==="without additional sheet"){
        return {
          ...state,
          cartItems: state.cartItems.filter(
          cartItem => cartItem.id !== action.payload.id )
        };
      }else{
        return {
          ...state,
          cartItems: calculateAndgetCartDetails(state.cartItems, action.payload)
        };
      };
   
    case CartActionTypes.CLEAR_CART:
      return {
        ...state,
        cartItems:[]
      };
    
    case CartActionTypes.UPDATE_COREFNO:
      return {
        ...state,
        corefno: action.payload
      };

    case CartActionTypes.UPDATERATEINTOINFLATEDRATE:
      return {
        ...state,
        cartItems: state.cartItems.map(item => (
          {
            ...item,
            rate:item.inflatedRate
          }
        ))
      };

      case CartActionTypes.FETCH_CART_DETAILS:
        return {
          ...state,
          cartItems: fetchCartDetails(state.cartItems)
        };
      // case CartActionTypes.TOGGLE_HEADER_HIDDEN:
      // return {
      //   ...state,
      //   headerHidden: !state.headerHidden 
      // };
    default:
      return state;
  }
};

export default cartReducer;