import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Chart from '../Chart';
import Deposits from '../Deposits';
import Orders from '../Orders';
import Products from '../Products';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Title from '../Title';
import SuspectAcmast from '../SuspectAcmast';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vasantslabels.com/">
        vasantslabels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
 
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const DashboardHome =({history}) =>{
  const classes = useStyles();
  const [showProducts, setShoProducts] = useState(false);
  const [showSuspectAcmast, setShowSuspectAcmast] = useState(false);
 
 

  const handleSubmitShowProduct = async() => {
    setShoProducts(!showProducts);
  }
  const handleSubmitShowSuspectAcmast = () => {
    setShowSuspectAcmast(!showSuspectAcmast);
  }

  const handleSubmitAccountCode =async(data,setAc_Code,setSuspectAcmast)=>{
    const config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.post("ACMASTDT",data,config)
    .then(response => { 
      if(response.data!==false){
        setSuspectAcmast(response.data);
        alert("Ac_Code Submitted Sccessfully 🔥");
        // setShowSuspectAcmast(false);
        setAc_Code(null);
        // setShowSuspectAcmast(true);
      }else{
        alert("Server Error ! 👎")
      }
    })
    .catch(error => {
      alert("Server Error ! 👎")
    });
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits />
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid>
            <Grid item xs={12}>
            <Paper className={classes.paper}>
               
                <Title>Show All Products</Title>
                {
                  showProducts ? <ArrowDropUpIcon onClick={handleSubmitShowProduct} /> : <ArrowDropDownIcon onClick={handleSubmitShowProduct} />
                }
                {
                  showProducts ? <Products /> : null
                }
                
            </Paper>
            </Grid>
            <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Title>Show All Suspect Accounts</Title>
                {
                  showSuspectAcmast ? <ArrowDropUpIcon onClick={handleSubmitShowSuspectAcmast} /> : <ArrowDropDownIcon onClick={handleSubmitShowSuspectAcmast} />
                }
                {
                  showSuspectAcmast ? <SuspectAcmast handleSubmitAccountCode={handleSubmitAccountCode} /> : null
                }
               
                
            </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
  );
}
export default withRouter(DashboardHome);