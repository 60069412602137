import React from "react";


const About = () => {
  return (
    <>
      {/* <Commom
        name="Welcome to About page"
        imgsrc={web}
        visit="/contact"
        btname="Contact Now"
      /> */}
    </>
  );
};

export default About;
