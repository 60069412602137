import styled from 'styled-components';

export const SpinnerOverlay = styled.div`
content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.8);
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
