import React,{useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { withRouter } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SigninAdmin = ({setUser,setSpinnerActive,history}) => {
  const classes = useStyles();
  const [data, setLoginData] = useState({
    username: "",
    password: "",
  });

  const formSubmit = async(e) => {
        e.preventDefault();
        const logindata={
          username: data.username,
          password: data.password
        }
        
        await axios.post(`authenticateAdmin`,logindata).then(
          response => {
            // console.log(response);
                localStorage.setItem('token',response.data.token);
                localStorage.setItem('username',response.data.username);
                setUser(response.data);
                setSpinnerActive(true);
                history.push('/');
          }
        )
        .catch(error => {
          alert("Username or Password Is Incorrect Please Login Again")
        });
      };
      
  const InputEvent = (event) => {
    const { name, value } = event.target;

    setLoginData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={formSubmit}>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="username"
            label="login Id"
            name="username"
            value={data.username}
            onChange={InputEvent}
            autoFocus
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={data.password}
            onChange={InputEvent}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default withRouter(SigninAdmin);