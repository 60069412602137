import React from "react";

const Footer = (props) => {
  const date= new Date();
  const onClickTermsAndConditionPolicy =()=> {
    props.setOpenPopup(true);
  }
  return (
    <>
    <div className="pt-4">
    <footer className="w-100 bg-light text-center footer-main-dev">
        <p>© {date.getFullYear()} Vasant Labels. All Rights Reserved | <a onClick={onClickTermsAndConditionPolicy} className="footer-term-condition">Terms and Conditions | Privacy Policy</a></p>
      </footer>
    </div>
    </>
  );
};

export default Footer;
