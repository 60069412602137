import React from 'react';
import { IconButton, Badge} from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import {connect} from 'react-redux';
import {toggleCartHidden} from '../../redux/cart/cart.actions';
import { selectCartItemsCount } from '../../redux/cart/cart.selectors';
import { selectCartItems } from '../../redux/cart/cart.selectors';
import {createStructuredSelector} from 'reselect';

const CartIcon =({toggleCartHidden,itemCount,cartItems}) => (
    <IconButton color="primary" onClick={toggleCartHidden}>
    <Badge badgeContent={cartItems.length} color="primary">
        <AddShoppingCartIcon  fontSize="small"/>
    </Badge>
    </IconButton>
);

// const mapDispatchToProps = dispatch => ({
//     toggleCartHidden: cart => dispatch(toggleCartHidden(cart))
//   });

// export default connect(
//     null,
//     mapDispatchToProps
//     )(CartIcon);

 const mapDispatchToProps = dispatch => ({
  toggleCartHidden: () => dispatch(toggleCartHidden())
});

const mapStateToProps = createStructuredSelector({
  itemCount: selectCartItemsCount,
  cartItems: selectCartItems
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartIcon);   
  