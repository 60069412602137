import React, { useState,useEffect } from "react";
import {Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import {setCurrentUser} from '../redux/user/user.actions';
import { AppBar, Toolbar, Grid, InputBase, IconButton, Badge, makeStyles,InputLabel,CardMedia } from '@material-ui/core'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
// import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CartDropdown from './cart-dropdown/cart-dropdown.component';
import CartIcon from'./cart-icon/cart-icon.component';
import { createStructuredSelector } from 'reselect';
import {selectCartHidden} from '../redux/cart/cart.selectors';
import {selectCurrentUser} from '../redux/user/user.selectors';
import {setShoppingData} from '../redux/shop/shop.action';
import VasantsLabelsLogo from '../images/VasantsLabelsLogo.jpg';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        
    },
    searchInput: {
        opacity: '0.6',
        padding: `0px ${theme.spacing(1)}px`,
        fontSize: '0.8rem',
        '&:hover': {
            backgroundColor: '#f2f2f2'
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1)
        }
    },
    labelName:{
        paddingRight:theme.spacing(2)
    },
    vasantsLabelIcon:{
        width:187,
        height:36

    },
}))

const Header =(props) => {

    const {setCurrentUser, setShoppingData, searchInput, setFilterFn, hidden, currentUser, history}=props;
    const [isLogout,setisLogout]=useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const {setUser}=props;
    const isMenuOpen = Boolean(anchorEl);
    const classes = useStyles();
    const[category,setCategory]=useState([]);
    const[data,setData]=useState();
    // const handleShoppingCartMenuOpen =() => {
    //     console.log("click on Shopping");
    //     setCart(true);
    // }
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // const handleMobileMenuClose = () => {
    //     setMobileMoreAnchorEl(null);
    // };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        // handleMobileMenuClose();
        
    };
    const handleIconImage =()=>{
        history.push('./');
    }
    const onChangeLogout = () => {
        setAnchorEl(null);
        setShoppingData([]);
        localStorage.clear()
        setCurrentUser(null);
        setisLogout(true);
    }

    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.docno.toLowerCase().includes(target.value.toLowerCase()))
            }
        })
      }

    const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      
      <MenuItem onClick={()=>{ history.push("/")}}>Home</MenuItem>
      <MenuItem onClick={()=>{ history.push("/profile")}}>Profile</MenuItem>
      <MenuItem onClick={()=>{ history.push("/orders")}}>My Orders</MenuItem>
      <MenuItem onClick={onChangeLogout}>Logout</MenuItem>
    </Menu>
  );

//   useEffect(()=>{
//     getCategory();
//   },[]);

// const handleinput = (e) =>{
// setData(e.target.value);
// console.log(e.target.value)
// }
// const getCategory = () =>{
//  const brand="BABYHUG";
//   const config ={
//     headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
// }
// axios.get(`getCategory/${brand}`,config).then(
//     response =>{
//       setCategory(response.data);
//         console.log(response.data);
//     }
// )
// .catch(error =>{
//     alert("City Is Not Awailable Select None");
// })
// }
    return (
        <>
        <AppBar position="static" className={classes.root}>
            <Toolbar>
                <Grid container
                    alignItems="center">
                    <Grid item>
                    
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="primary-search-account-menu"
                          aria-haspopup="true"
                          onClick={handleProfileMenuOpen}>
                            <AccountCircle />
                        </IconButton>
                        <IconButton onClick={handleIconImage}>
                        <CardMedia
                            component="img"
                            src={VasantsLabelsLogo}
                            className={classes.vasantsLabelIcon}
                        />
                        </IconButton>
                         
                        {searchInput ?
                        <InputBase
                            placeholder="Search topics"
                            className={classes.searchInput}
                            startAdornment={<SearchIcon fontSize="small" />}
                            onChange={handleSearch}
                        />:null }
                        
                    </Grid>
                    {/* <Grid> */}
                    {/* <select onchange={handleinput}>
               <option value={category.value}>{category.groupname}</option>
                    {category.map(category => (
              <option key={category.id} id={category.id} name={category.id}>{category}</option>
              ))}
           </select> */}
           {/* </Grid> */}
                    <Grid item sm></Grid>
                    <Grid item>
                        {
                            currentUser ?
                            <InputLabel className={classes.labelName}>
                            Hello, {currentUser.username}
                            </InputLabel>:null
                        }
                    </Grid>
                    <Grid item>
                        <IconButton>
                            <Badge badgeContent={0} color="secondary">
                                <NotificationsNoneIcon fontSize="small" />
                            </Badge>
                        </IconButton>
                        <IconButton>
                            <Badge badgeContent={0} color="primary">
                                <ChatBubbleOutlineIcon fontSize="small" />
                            </Badge>
                        </IconButton>
                        <CartIcon/>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        {
        hidden ? null :  <CartDropdown/>
        }
        {renderMenu}
        {
            (isLogout)? <Redirect to="/" /> : ""
        }
        </>
    )
}

const mapSateToProps = createStructuredSelector({
    currentUser:selectCurrentUser,
    hidden:selectCartHidden
  });
  
const mapDispatchToProps = dispatch =>({
    setCurrentUser: user =>dispatch(setCurrentUser(user)),
    setShoppingData:products => dispatch(setShoppingData(products)),
  });
  export default withRouter(connect(
    mapSateToProps,mapDispatchToProps
    )(Header));
  
