import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import controls from '../controls/Controls';
import axios from 'axios';
import _ from 'lodash';

const SuspectAcmast = (props) => {

  const {handleSubmitAccountCode}=props;
  const [Acmast, setSuspectAcmast]= useState([]);
  const [isAc_Code,setAc_Code]= useState();

  useEffect( () => {
    const config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    axios.get(`ACMASTDT`,config)
    .then(response => { 
      setSuspectAcmast(response.data);
    })
    .catch(error => {
      alert("Server Error ! 👎")
    });
    
    },[]);

    const hiddenInputAc_code = (event) =>{
        const {value,name} = event.target;
        setAc_Code({
          [name] : value,
        });
    };

  const handleSubmit = (event) =>{
    
    const {name} = event.currentTarget;
      const data ={
        inputData : isAc_Code[name],
        name: name,
      }
      handleSubmitAccountCode(data,setAc_Code,setSuspectAcmast); 
  }

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>AC_CODE</TableCell>
            <TableCell>AC_NAME</TableCell>
            <TableCell>GRP_CODE</TableCell>
            <TableCell>PERSON</TableCell>
            <TableCell align="right">Submit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Acmast.map((val,ind) => (
            <TableRow key={ind}>
              <TableCell>
                  <controls.Input
                   margin="normal" 
                  name={val.usercd}
                  label="Ac_Code"
                  id={val.usercd}
                  onChange={hiddenInputAc_code}
                  inputProps={{
                    maxLength: 4,
                  }}
                  />
                  </TableCell>
              <TableCell>{val.acname}</TableCell>
              <TableCell>{val.grpcode}</TableCell>
              <TableCell>{val.person}</TableCell>
              <TableCell align="right">
                <controls.Button
                  text="Submit"
                  color="default"
                  name={val.usercd}
                  onClick={handleSubmit}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
  
  export default SuspectAcmast;