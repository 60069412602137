import React,{useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
// import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { red } from '@material-ui/core/colors';
import ConfirmDialog from "../ConfirmDialog";


export function ListItems(history,setCurrentUser) {

  // const {history,setCurrentUser}=props;
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

   const onClickHandler =(event)=>{
    const {id} = event.currentTarget;
    history.push(`/adDashboard/${id}`);
   } 
   const onClickLogoutButton =()=> {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
    setCurrentUser(null);
    localStorage.clear();
    history.push("/");
    }

   const mainListItems = (
    <div>
      <ListItem button id="dashboard" onClick={onClickHandler}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button id="upload" onClick={onClickHandler}>
        <ListItemIcon>
          <PublishIcon />
        </ListItemIcon>
        <ListItemText primary="File Upload" />
      </ListItem>
      <ListItem button id="orders" onClick={onClickHandler}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
      <ListItem button id="customers" onClick={onClickHandler}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Customers" />
      </ListItem>
      <ListItem button id="invoicedownload" onClick={onClickHandler}>
        <ListItemIcon>
          <PublishIcon/>
        </ListItemIcon>
        <ListItemText primary="Invoice Download" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItem>
    </div>
  );
  
   const secondaryListItems = (
    <div>
      <ListSubheader inset>Saved reports</ListSubheader>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Current month" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last quarter" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Year-end sale" />
      </ListItem>
      <ListItem button onClick={() => {
        setConfirmDialog({
          isOpen: true,
          title: 'Confirm LogOut',
          subTitle: "Are you sure you want to LogOut?",
          onConfirm: () => { 
            onClickLogoutButton() 
          }
        })
      }}
      >
        <ListItemIcon>
          <SettingsPowerIcon style={{ color: red[500] }} />
        </ListItemIcon>
        <ListItemText primary="LogOut" />
      </ListItem>
      <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
  
  
  return {
    mainListItems,
    secondaryListItems,
  };

}
